<template>
  <div id="inbox-mailbox-provider__filter-form">
    <v-row>
      <v-spacer />
      <v-col
        cols="12"
        lg="5"
        class="py-0"
        v-if="!hasSendingDomains"
        align-self="start"
      >
        <div class="form-group">
          <v-autocomplete
            multiple
            outlined
            small-chips
            return-object
            class="required"
            :loading="isLoading"
            item-text="name"
            placeholder="Mailbox Provider"
            item-value="name"
            v-model="mailboxProviders"
            :prepend-inner-icon="prependFilterIcon"
            :items="mailboxProvidersList"
            clearable
            hide-details="auto"
            :id="`mailbox-providers__dropdown${_uid}`"
            no-data-text="No mailbox providers found"
            :rules="mailboxProviderRules"
          >
            <template
              v-slot:selection="{ item, attrs, index, selected, select }"
            >
              <v-chip
                v-if="index === 0"
                class="filter-light-green-chip body-2 text--primary"
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="remove(item)"
              >
                {{ item.name }}
              </v-chip>
              <tooltip
                contentClass="white-space-preline"
                #default="{ on, attrs }"
                :name="mailboxProvidersTooltip"
                :type="$appConfig.tooltip.type.dark"
              >
                <span
                  v-bind="attrs"
                  v-on="on"
                  v-if="index === 1"
                  class="dark-grey caption cursor-pointer"
                >
                  (+{{ mailboxProviders.length - 1 }} others)
                </span>
              </tooltip>
            </template>
            <template v-slot:item="{ on, attrs, item }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action class="mr-4">
                  <v-checkbox :input-value="active" />
                </v-list-item-action>
                <v-list-item-content class="body-2 text--primary">
                  {{ item.name }}
                  <v-spacer />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
      </v-col>
      <v-col class="py-0" cols="12" lg="5" v-if="hasSendingIpsFilter">
        <div class="form-group">
          <v-autocomplete
            :items="sendingIPs"
            v-model="ip_addresses"
            class="required"
            item-value="sending_ip"
            item-text="sending_ip"
            small-chips
            outlined
            multiple
            clearable
            placeholder="IP Address"
            :prepend-inner-icon="prependFilterIcon"
            hide-details="auto"
            :menu-props="{ bottom: true }"
            item-color="primary"
            :validate-on-blur="areTrendsLoading"
            :loading="isLoading"
            no-data-text="No ip address found"
            :rules="[minLength(1, 'ip address'), maxLength(4, 'ip address')]"
          >
            <template
              v-slot:selection="{ item, attrs, index, selected, select }"
            >
              <v-chip
                v-if="index === 0"
                class="filter-light-green-chip body-2 text--primary"
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="removeIpAddress(item)"
              >
                {{ item.sending_ip }}
              </v-chip>
              <tooltip
                contentClass="white-space-preline"
                #default="{ on, attrs }"
                :name="ipAddressTooltip"
                :type="$appConfig.tooltip.type.dark"
              >
                <span
                  v-bind="attrs"
                  v-on="on"
                  v-if="index === 1"
                  class="dark-grey caption cursor-pointer"
                >
                  (+{{ ip_addresses.length - 1 }} others)
                </span>
              </tooltip>
            </template>

            <template v-slot:item="{ on, attrs, item }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action class="mr-4">
                  <v-checkbox :input-value="active" />
                </v-list-item-action>
                <v-list-item-content class="body-2 text--primary">
                  {{ item.sending_ip }}
                  <v-spacer />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete></div
      ></v-col>
      <v-col cols="12" lg="5" class="py-0" v-if="hasSendingDomains">
        <div class="form-group">
          <v-autocomplete
            multiple
            outlined
            small-chips
            return-object
            class="required"
            item-text="from_domain"
            item-value="from_domain"
            v-model="sending_domains"
            :items="sendingDomains"
            hide-details="auto"
            clearable
            :validate-on-blur="areTrendsLoading"
            placeholder="From Domains"
            :id="`sending-domains${_uid}`"
            :prepend-inner-icon="prependFilterIcon"
            no-data-text="No from domains found"
            :rules="[
              minLength(1, 'from domains'),
              maxLength(4, 'from domains'),
            ]"
          >
            <template
              v-slot:selection="{ item, attrs, index, selected, select }"
            >
              <v-chip
                v-if="index === 0"
                class="filter-light-green-chip body-2 text--primary"
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="removeSendingDomain(item)"
              >
                {{ item.from_domain }}
              </v-chip>
              <tooltip
                contentClass="white-space-preline"
                #default="{ on, attrs }"
                :name="seedDomainsTooltip"
                :type="$appConfig.tooltip.type.dark"
              >
                <span
                  v-bind="attrs"
                  v-on="on"
                  v-if="index === 1"
                  class="dark-grey caption cursor-pointer"
                >
                  (+{{ sending_domains.length - 1 }} others)
                </span>
              </tooltip>
            </template>
            <template v-slot:item="{ on, attrs, item }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action class="mr-4">
                  <v-checkbox :input-value="active" />
                </v-list-item-action>
                <v-list-item-content class="body-2 text--primary">
                  {{ item.from_domain }}
                  <v-spacer />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from "@/validators/form-valdators";
import { mapGetters, mapMutations } from "vuex";
import { isEmpty, getPropArr } from "@/utils/common.utils";
import Tooltip from "@/components/shared/Tooltip.vue";

const TOOLTIP_SEPERATOR = "\n";

/**
 * Dashboard inbox rate mailbox provider widget filter form
 */
export default {
  name: "InboxMailboxProviderFilterForm",
  /**
   * Custom events to be emitted by the component
   */
  emits: ["filters-applied", "set-sending-ips"],
  /**
   * -------------- Injected properties --------------
   */
  inject: ["defaultMailboxProviders"],
  /**
   * -------------- Components --------------
   */
  components: {
    Tooltip,
  },
  /**
   * -------------- Data properties --------------
   */
  data() {
    return {
      isFormValid: false,
      prependFilterIcon: "mdi-filter-variant",
    };
  },
  /**
   * -------------- Methods --------------
   */
  methods: {
    ...mapMutations({
      setInboxRateWidgetFilters:
        "settings/SET_INBOX_RATE_TREND_WIDGET_SETTINGS",
    }),

    isEmpty,
    required,
    minLength(length) {
      return (val) =>
        val?.length >= length || `Minimum of ${length} selections at a time`;
    },
    maxLength(length) {
      return (val) =>
        val?.length <= length || `Maximum of ${length} selections at a time`;
    },
    /**
     * Removes the selected mailbox provider
     * @param {String} provider Mailbox provider to be removed
     */
    remove({ id }) {
      this.mailboxProviders = this.mailboxProviders.filter(
        (provider) => provider.id !== id
      );
    },
    /**
     * Removes the ip address
     * @param {String} sending_ip Ip Address to be removed
     */
    removeIpAddress({ sending_ip }) {
      this.ip_addresses = this.ip_addresses.filter(
        (ipAddress) => ipAddress !== sending_ip
      );
    },
    /**
     * Removes the sending domains
     * @param {String} from_domain Sending domain to be removed
     */
    removeSendingDomain({ from_domain }) {
      this.sending_domains = this.sending_domains.filter(
        (domain) => domain.from_domain !== from_domain
      );
    },
    computeFiltersTootip(data, prop) {
      const hasData = !isEmpty(data);
      const propsArr = getPropArr(data, prop);

      // Removes first element of the array if lenght of array is greater than 1
      if (hasData && propsArr.length > 1) propsArr.shift();
      return hasData ? propsArr.join(TOOLTIP_SEPERATOR) : "";
    },
  },
  /**
   * -------------- Computed properties --------------
   */
  computed: {
    ...mapGetters({
      allMailboxProviders: "account/mailboxProviders",
      sendingIPs: "account/sendingIPs",
      selectedAccount: "ui/selectedAccount",
      inboxRateTrendWidget: "settings/inboxRateTrendWidget",
      sendingDomains: "account/sendingDomains",
      areTrendsLoading: "settings/areTrendsLoading",
      isLoading: "settings/areTrendFiltersLoading",
    }),
    /**
     * Mailbox provider rules
     */
    mailboxProviderRules() {
      const rules = [this.maxLength(4, "mailbox providers")];
      if (!this.isLoading) rules.push(this.minLength(1, "mailbox provider"));

      return rules;
    },
    /**
     * Mailbox providers dropdown model
     * @model
     */
    mailboxProviders: {
      get() {
        let { domains } = this.inboxRateTrendWidget.filters;
        domains = domains ?? [];

        return domains;
      },
      set(domains) {
        // Restricts user to select more than 4 mailbox providers
        if (domains && domains.length > 4) return;
        this.$nextTick(() => {
          this.setInboxRateWidgetFilters({ filters: { domains } });
        });
      },
    },
    ip_addresses: {
      get() {
        let { ip_addresses } = this.inboxRateTrendWidget.filters;
        ip_addresses = ip_addresses ?? [];

        return ip_addresses;
      },
      set(ip_addresses) {
        // Restricts user to select more than 4 ip addresses
        if (ip_addresses && ip_addresses.length > 4) return;
        this.$nextTick(() => {
          return this.setInboxRateWidgetFilters({ filters: { ip_addresses } });
        });
      },
    },
    sending_domains: {
      get() {
        let { sending_domains } = this.inboxRateTrendWidget.filters;
        sending_domains = sending_domains ?? [];

        return sending_domains;
      },
      set(sending_domains) {
        // Restricts user to select more than 4 ip addresses
        if (sending_domains && sending_domains.length > 4) return;
        this.$nextTick(() => {
          this.setInboxRateWidgetFilters({ filters: { sending_domains } });
        });
      },
    },
    hasSendingIpsFilter() {
      return this.inboxRateTrendWidget.hasSendingIpsFilter;
    },
    hasSendingDomains() {
      return this.inboxRateTrendWidget.hasSendingDomainsFilter;
    },
    /**
     * Selected mailbox provider tooltip text
     * @type {String}
     */
    mailboxProvidersTooltip() {
      const PROP = "name";
      return this.computeFiltersTootip(this.mailboxProviders, PROP);
    },
    seedDomainsTooltip() {
      const PROP = "from_domain";
      return this.computeFiltersTootip(this.sending_domains, PROP);
    },
    /**
     * Selected mailbox provider tooltip text
     * @type {String}
     */
    ipAddressTooltip() {
      const hasData = !isEmpty(this.ip_addresses);
      const dataArr = [...this.ip_addresses];

      // Removes first element in the array
      dataArr.shift();
      return hasData ? dataArr.join(TOOLTIP_SEPERATOR) : "";
    },
    /** All mailbox providers which are returned by the api call
     * @type {Array} Mailbox providers list
     */
    mailboxProvidersList() {
      return this.allMailboxProviders;
    },
  },
};
</script>
