var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"inbox-mailbox-provider__filter-form"}},[_c('v-row',[_c('v-spacer'),(!_vm.hasSendingDomains)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"5","align-self":"start"}},[_c('div',{staticClass:"form-group"},[_c('v-autocomplete',{staticClass:"required",attrs:{"multiple":"","outlined":"","small-chips":"","return-object":"","loading":_vm.isLoading,"item-text":"name","placeholder":"Mailbox Provider","item-value":"name","prepend-inner-icon":_vm.prependFilterIcon,"items":_vm.mailboxProvidersList,"clearable":"","hide-details":"auto","id":("mailbox-providers__dropdown" + _vm._uid),"no-data-text":"No mailbox providers found","rules":_vm.mailboxProviderRules},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var index = ref.index;
var selected = ref.selected;
var select = ref.select;
return [(index === 0)?_c('v-chip',_vm._b({staticClass:"filter-light-green-chip body-2 text--primary",attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),_c('tooltip',{attrs:{"contentClass":"white-space-preline","name":_vm.mailboxProvidersTooltip,"type":_vm.$appConfig.tooltip.type.dark},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(index === 1)?_c('span',_vm._g(_vm._b({staticClass:"dark-grey caption cursor-pointer"},'span',attrs,false),on),[_vm._v(" (+"+_vm._s(_vm.mailboxProviders.length - 1)+" others) ")]):_vm._e()]}}],null,true)})]}},{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"body-2 text--primary"},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-spacer')],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,261650550),model:{value:(_vm.mailboxProviders),callback:function ($$v) {_vm.mailboxProviders=$$v},expression:"mailboxProviders"}})],1)]):_vm._e(),(_vm.hasSendingIpsFilter)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"5"}},[_c('div',{staticClass:"form-group"},[_c('v-autocomplete',{staticClass:"required",attrs:{"items":_vm.sendingIPs,"item-value":"sending_ip","item-text":"sending_ip","small-chips":"","outlined":"","multiple":"","clearable":"","placeholder":"IP Address","prepend-inner-icon":_vm.prependFilterIcon,"hide-details":"auto","menu-props":{ bottom: true },"item-color":"primary","validate-on-blur":_vm.areTrendsLoading,"loading":_vm.isLoading,"no-data-text":"No ip address found","rules":[_vm.minLength(1, 'ip address'), _vm.maxLength(4, 'ip address')]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var index = ref.index;
var selected = ref.selected;
var select = ref.select;
return [(index === 0)?_c('v-chip',_vm._b({staticClass:"filter-light-green-chip body-2 text--primary",attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeIpAddress(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.sending_ip)+" ")]):_vm._e(),_c('tooltip',{attrs:{"contentClass":"white-space-preline","name":_vm.ipAddressTooltip,"type":_vm.$appConfig.tooltip.type.dark},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(index === 1)?_c('span',_vm._g(_vm._b({staticClass:"dark-grey caption cursor-pointer"},'span',attrs,false),on),[_vm._v(" (+"+_vm._s(_vm.ip_addresses.length - 1)+" others) ")]):_vm._e()]}}],null,true)})]}},{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"body-2 text--primary"},[_vm._v(" "+_vm._s(item.sending_ip)+" "),_c('v-spacer')],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,4239385264),model:{value:(_vm.ip_addresses),callback:function ($$v) {_vm.ip_addresses=$$v},expression:"ip_addresses"}})],1)]):_vm._e(),(_vm.hasSendingDomains)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"5"}},[_c('div',{staticClass:"form-group"},[_c('v-autocomplete',{staticClass:"required",attrs:{"multiple":"","outlined":"","small-chips":"","return-object":"","item-text":"from_domain","item-value":"from_domain","items":_vm.sendingDomains,"hide-details":"auto","clearable":"","validate-on-blur":_vm.areTrendsLoading,"placeholder":"From Domains","id":("sending-domains" + _vm._uid),"prepend-inner-icon":_vm.prependFilterIcon,"no-data-text":"No from domains found","rules":[
            _vm.minLength(1, 'from domains'),
            _vm.maxLength(4, 'from domains') ]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
          var attrs = ref.attrs;
          var index = ref.index;
          var selected = ref.selected;
          var select = ref.select;
return [(index === 0)?_c('v-chip',_vm._b({staticClass:"filter-light-green-chip body-2 text--primary",attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeSendingDomain(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.from_domain)+" ")]):_vm._e(),_c('tooltip',{attrs:{"contentClass":"white-space-preline","name":_vm.seedDomainsTooltip,"type":_vm.$appConfig.tooltip.type.dark},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(index === 1)?_c('span',_vm._g(_vm._b({staticClass:"dark-grey caption cursor-pointer"},'span',attrs,false),on),[_vm._v(" (+"+_vm._s(_vm.sending_domains.length - 1)+" others) ")]):_vm._e()]}}],null,true)})]}},{key:"item",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
          var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"body-2 text--primary"},[_vm._v(" "+_vm._s(item.from_domain)+" "),_c('v-spacer')],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,2818785630),model:{value:(_vm.sending_domains),callback:function ($$v) {_vm.sending_domains=$$v},expression:"sending_domains"}})],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }