<template>
  <card-widget
    :height="containerHeight"
    cardStyle="pa-4"
    containerClasses="pa-0"
  >
    <template #cardBody>
      <v-row no-gutters>
        <v-col
          align-self="center"
          md="12"
          lg="4"
          class="text-left d-flex align-center"
        >
          <h2
            class="subtitle-1 font-weight-large text-black--80 secondary--font"
          >
            Inbox Rate Trend by
          </h2>
          <v-menu offset-y @input="isMenuOpen = $event">
            <template #activator="{ on, attrs }">
              <v-btn
                text
                plain
                color="primary"
                v-bind="attrs"
                v-on="on"
                elevation="0"
                :ripple="false"
                class="subtitle-1 text-capitalize font-weight-large transparent px-1"
              >
                {{ selectedWidget.name }}

                <v-icon color="primary">
                  {{ isMenuOpen ? "mdi-menu-up" : "mdi-menu-down" }}
                </v-icon>
              </v-btn>
            </template>
            <v-expand-transition>
              <v-list dense>
                <v-list-item-group color v-model="selectedWidget">
                  <v-list-item
                    ripple
                    :value="widget"
                    color="primary"
                    :key="widget.component"
                    v-for="widget in widgets"
                    class="text--primary body-2"
                  >
                    <v-list-item-title>
                      {{ widget.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-expand-transition>
          </v-menu>
        </v-col>
        <v-col lg="8" md="12">
          <inbox-mailbox-provider-filter-form />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col lg="12" md="12" cols="12">
          <component
            :is="selectedWidget.component"
            :selectedDay="settings.selectedDay"
          />
        </v-col>
      </v-row>
    </template>
  </card-widget>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import InboxMailboxProviderFilterForm from "../forms/InboxMailboxProviderFilterForm";
import {
  CardWidget,
  MailboxProviderTrend,
  MailboxProvidersIpTrends,
  SeedDomainTrends,
} from "../widgets";
import { INBOX_RATE_TREND_WIDGETS, DISPLAY_BREAKPOINTS } from "@/constants";

/**
 * Inbox rate trends widget
 */
export default {
  name: "InboxRateTrendWidgets",
  /**
   * ---------------- Components ------------------
   */
  components: {
    CardWidget,
    SeedDomainTrends,
    MailboxProviderTrend,
    MailboxProvidersIpTrends,
    InboxMailboxProviderFilterForm,
  },
  /**
   * ---------------- Computed ------------------
   */
  computed: {
    ...mapGetters({
      settings: "settings/dashboard",
      inboxRateTrendWidget: "settings/inboxRateTrendWidget",
    }),
    /**
     * Selected inbox rate trend widget
     * @model
     */
    selectedWidget: {
      get() {
        return this.inboxRateTrendWidget?.activeWidget;
      },
      set(activeWidget) {
        if (!activeWidget) return;

        const { name } = this.inboxRateTrendWidget?.activeWidget;
        const isWidgetSame = activeWidget?.name === name;

        if (!isWidgetSame) this.setActiveWidget({ activeWidget });
      },
    },
    /**
     * Inbox rate trends widget Container height
     * @type {String}
     */
    containerHeight() {
      let [LG, SM, MD, height] = ["459px", "600px", "530px", ""];
      const { name } = this.$vuetify.breakpoint;

      switch (name) {
        case DISPLAY_BREAKPOINTS.sm:
          height = SM;
          break;

        case DISPLAY_BREAKPOINTS.md:
        case DISPLAY_BREAKPOINTS.xs:
          height = MD;
          break;

        default:
          height = LG;
          break;
      }

      return height;
    },
  },
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    const widgets = [...INBOX_RATE_TREND_WIDGETS];
    return {
      widgets,
      isMenuOpen: false,
    };
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    ...mapMutations({
      setActiveWidget: "settings/SET_INBOX_RATE_TREND_ACTIVE_WIDGET",
    }),
  },
};
</script>
