<template>
  <section id="dashboard">
    <page-header>
      <template #header>
        <v-col cols="12" md="8" lg="6" class="my-4">
          <h1
            class="mt-2 text-capitalize secondary--font text-h4 text-black--80 font-weight-large mb-1"
          >
            {{ title }}
          </h1>
          <span class="subtitle-1 font-weight-regular text-dark-grey darken-3">
            {{ $dictionary.app.dashboard.index.subTitle }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="6"
          class="my-4 text-dark-grey d-flex justify-end align-end"
        >
          <days-filter
            :items="daysFilterItems"
            :selectedDay="settings.selectedDay"
            @update-day="setSelectedDay"
            appendText="Last"
            prependIcon="mdi-calendar"
          />
        </v-col>
      </template>
    </page-header>
    <!----------------------- MailBox provider chart starts  ----------------------->
    <v-row id="trend-chart">
      <v-col cols="12" md="12" lg="12">
        <inbox-rate-trend-widgets />
      </v-col>
    </v-row>
    <!----------------------- MailBox provider chart Ends  ----------------------->

    <div class="my-4">
      <!-- Recent Campaign Widget -->
      <card-widget>
        <template #cardBody>
          <v-row dense class="mb-6">
            <v-col md="12" lg="6" class="d-flex align-center">
              <h5 class="subtitle-1 secondary--font font-weight-large">
                {{ $dictionary.app.dashboard.index.campaigns.title }}
              </h5>
            </v-col>
            <v-col
              md="12"
              lg="6"
              class="d-flex justify-end recent-campaigns-filter-btn"
            >
              <days-filter
                :items="CampaignFilterItems"
                :selectedDay="settings.selectedMaxCampaigns"
                prependText="Campaigns"
                appendText="Last"
                prependIcon="mdi-filter-variant"
                @update-day="setMaxCampaigns"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <campaigns-table
                :isLoading="isLoading"
                :height="290"
                :campaignAccount="campaignAccount"
                :accountCampaigns="filteredAccountCampaigns"
                hideDefaultFooter
                :maxRowDisplay="settings.selectedMaxCampaigns"
                isClientSideSort
                :tableSettings="settings.recentCampaigns"
                :updateSettings="setDashboardSettings"
                :disablePagination="true"
                @get-campaigns="getAccountCampaignsList"
                @navigate-to-mailbox="mailboxProviderNavigator"
                :headers="campaignHeaders"
              />
            </v-col>
          </v-row>
        </template>
      </card-widget>
      <!-- End: Recent Campaign widget -->
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { CampaignsTable } from "@/components/tables";
import { CardWidget } from "@/components/widgets";
import { PageHeader, DaysFilter } from "@/components/shared";
import { DEFAULT_MAILBOX_PROVIDERS, SORTING_ORDERS } from "@/constants/app";
import InboxRateTrendWidgets from "../components/widgets/InboxRateTrendWidgets";
import { campaignHeaders as headers } from "@/data/table-headers";
import cloneDeep from "lodash/cloneDeep";

export default {
  /**
   *  Dashboard Component
   */
  name: "dashboard",
  /**
   * -------------Components -------------------
   */
  components: {
    CardWidget,
    CampaignsTable,
    PageHeader,
    DaysFilter,
    InboxRateTrendWidgets,
  },
  /**
   * -------------- Data Properties -------------
   */
  data: () => {
    return {
      isLoading: false,
      isTrendsLoading: true,
      errorMessage: null,
      hasError: false,
      CampaignFilterItems: [5, 10, 20, 30],
      daysFilterItems: [7, 14, 30, 90],
      filterDates: {},
      defaultMailboxProviders: [...DEFAULT_MAILBOX_PROVIDERS],
      campaignHeaders: [],
    };
  },
  /**
   * ---------------- Provide properties and functions ------------------
   */
  provide() {
    return {
      defaultMailboxProviders: this.defaultMailboxProviders,
    };
  },

  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      filterCampaigns: "account/filteredCampaigns",
      accountCampaigns: "account/accountCampaigns",
      selectedAccount: "ui/selectedAccount",
      settings: "settings/dashboard",
      userProfile: "auth/currentUserProfile",
    }),
    filteredAccountCampaigns() {
      return this.filterCampaigns(this.accountCampaigns ?? []);
    },
    campaignAccount() {
      return { account_id: this.selectedAccount?.account_id };
    },
    /**
     * Title to be shown to the user
     */
    title() {
      const greeting = this.getGreetings();
      const hasFirstName = this.userProfile?.first_name?.trim();
      const greetings = hasFirstName
        ? `${greeting}, ${this.userProfile?.first_name}!`
        : `${greeting}`;

      return greetings;
    },
  },
  /**
   * ---------------- Watching properties -----------------------
   */
  watch: {
    "settings.selectedMaxCampaigns"(val, oldval) {
      if (!val || !oldval) this.setMaxCampaigns(val || oldval);

      !this.isLoading && this.loadCampaigns();
    },
    "selectedAccount.account_id"() {
      !this.isLoading && this.loadCampaigns();
    },
  },

  /**
   * ---------------- Methods ------------------
   */
  methods: {
    ...mapActions({
      getAccountCampaigns: "account/getAccountCampaigns",
      getMailboxProviderTrends: "account/getMailboxProviderTrends",
      updateDashboardSettings: "settings/updateDashboardSettings",
      setDashboardSettings: "settings/setDashboardSettings",
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Computed recent campaign headers
     */
    setCampaignHeaders() {
      const headersObj = cloneDeep(headers);
      headersObj[5] = { ...headersObj[5], value: "first_seen_date" };
      headersObj[7] = { ...headersObj[7], value: "durationMillis" };

      this.campaignHeaders = headersObj;
    },
    /**
     * Loads campaigns with the required request params
     */
    loadCampaigns() {
      this.getAccountCampaignsList({
        account_id: this.selectedAccount.account_id,
        per_page: this.settings.selectedMaxCampaigns,
        page: 1,
        "q[s]": ` first_seen ${SORTING_ORDERS.desc}`,
      });
    },
    /**
     * Fetches the list of the campaigns of an account
     */
    async getAccountCampaignsList(params) {
      try {
        this.isLoading = true;
        await this.getAccountCampaigns(params);
      } catch (error) {
        this.errorMessage = this.$errorMessage(error);
        this.hasError = true;
      } finally {
        this.isLoading = false;
      }
    },
    /* Updates the selected filter day value */
    setSelectedDay(val) {
      this.updateSettings({ selectedDay: val });
    },
    setMaxCampaigns(val) {
      this.updateSettings({ selectedMaxCampaigns: val });
    },
    /**
     * Navigates to the mailbox provider page
     * @param {String} campaign_id - Camapign id whose mailbox providers are to be viewed
     * @param {String} campaignSubject - Campaign subject which is to be displayed in the view
     * @param {String} account_id - Campaign belong to whose account id
     */
    mailboxProviderNavigator({ campaign_id, account_id }) {
      this.$router.push({
        name: "campaign-mailbox-providers",
        params: { campaignId: campaign_id, id: account_id },
      });
    },
    /**
     * Updates dashboard settings in the store\
     */
    updateSettings(settings) {
      this.updateDashboardSettings(settings);
    },
    /**
     * Returns the greeting to be shown to the user on the basis of current date hours
     * @returns {String} Greetings message to be shown to the user
     */
    getGreetings() {
      let greeting;
      const hours = new Date().getHours();
      const [MORNING_HOURS, AFTERNOON_HOURS] = [12, 17];
      const [MORNING, AFTERNOON, EVENING] = ["Morning", "Afternoon", "Evening"];

      if (hours < MORNING_HOURS) {
        greeting = MORNING;
      } else if (hours < AFTERNOON_HOURS) {
        greeting = AFTERNOON;
      } else {
        greeting = EVENING;
      }
      return `Good ${greeting}`;
    },
  },
  /**
  |--------------------------------------------------
  | Created lifecycle hook
  |--------------------------------------------------
  */
  created() {
    this.setCampaignHeaders();
  },
  /**
   * Mounted lifecycle
   */
  mounted() {
    this.selectedAccount?.account_id && this.loadCampaigns();
  },
};
</script>
